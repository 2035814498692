.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;
    background-color: #f6f9ff;
}

.coming-soon-container h1 {
    font-size: 2.5rem;
    color: #012970;
    margin-bottom: 20px;
}

.coming-soon-text {
    font-size: 1.5rem;
    color: #666666;
    background-color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f6f9ff;
    padding: 10px;
    z-index: 0;
    padding-top: 40px;
    padding-bottom: 94px;
}

.form-container h1 {
    font-size: 2.5rem;
    color: #012970;
    margin-bottom: 20px;
}

.iframe-wrapper {
    width: 100%;
    height: 80vh;
    overflow: auto;
}

.iframe-wrapper iframe {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: none;
}
